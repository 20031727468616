import { useEffect, useState } from 'react';

import Modal from 'components/ui/Modal';
import Input from 'components/ui/Input';
import Button from 'components/ui/Button';

import { useAttributes } from 'hooks/queries';
import { useCmsData } from 'contexts/CmsDataProvider';
import { getAttributeTranslation, numberWithSeparator } from 'utils/utils';

import type { AdditionalInfo } from 'types/types';

import { ReactComponent as BulbIcon } from 'assets/icons/bulb.svg';

type Props = {
  isOpen: boolean;
  onDismiss: () => any;
  onSubmit: (val: number) => any;
  min: number;
  max: number;
  vehicleInfo: AdditionalInfo['vehicleInfo'];
};

const EditMileageModal: React.FC<Props> = ({
  isOpen,
  vehicleInfo,
  onDismiss,
  onSubmit,
  min,
  max,
}) => {
  const [editedMileage, setEditedMileage] = useState<number | null>(null);

  useEffect(() => {
    setEditedMileage(null);
  }, [isOpen]);

  const { t, locale } = useCmsData();
  const { data: attributes } = useAttributes();
  const { make, model, exactModel, mileage, body } = vehicleInfo;

  return (
    <Modal
      isOpen={isOpen}
      title={t('edit_mileage_modal_title')}
      onDismiss={onDismiss}
      content={
        <div className="pb-[62px]">
          <div>
            {make} {model} {exactModel}{' '}
            {getAttributeTranslation(
              { key: 'bodyType', value: body },
              locale,
              attributes
            )}
          </div>
          <div>
            {numberWithSeparator(vehicleInfo.editedMileage || mileage)}{' '}
            {t('km')}
          </div>
          {!vehicleInfo.editedMileage ? (
            <>
              <Input
                className="mt-4 max-w-[295px]"
                label={t('edit_mileage_modal_input_label')}
                placeholder={t(
                  'edit_mileage_modal_input_placeholder',
                  {},
                  true
                )}
                value={editedMileage || ''}
                maxLength={7}
                type="number"
                onChange={(mielage) => setEditedMileage(mielage as number)}
                autoComplete="off"
              />
              <div className="mt-4 flex gap-x-2">
                <BulbIcon className="size-6 shrink-0" />
                <p>
                  {min && max
                    ? t('edit_mileage_modal_important_hint')
                    : t('edit_mileage_modal_important_hint_no_price')}
                </p>
              </div>
            </>
          ) : (
            <p className="mt-12">{t('edit_mileage_modal_no_more_edit_hint')}</p>
          )}
        </div>
      }
      footer={
        !vehicleInfo.editedMileage ? (
          <div className="flex items-center gap-x-3">
            <Button
              className="w-1/2"
              label={t('edit_mileage_modal_save_btn')}
              icon={null}
              isDisabled={!editedMileage}
              onClick={() => onSubmit(editedMileage!)}
            />
            <Button
              className="w-1/2 !bg-background !border !border-dark"
              label={t('edit_mileage_modal_cancel_btn')}
              icon={null}
              onClick={onDismiss}
            />
          </div>
        ) : (
          <Button
            className="w-full"
            label={t('edit_mileage_modal_ok_btn')}
            icon={null}
            onClick={onDismiss}
          />
        )
      }
    />
  );
};

export default EditMileageModal;
